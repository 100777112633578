import React from 'react';
import styles from './yuvaan.module.css';

export default function Yuvaan() {

    return (
        <section className='panel'>
            <div className={styles.yuvaan} id='productyuvaan'>
                <div className={styles.backbhai}>
                    {/* <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/decorationyuvaan_VfNXC9cLp.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648664095632" alt="yuv" className={styles.mountains} /> */}
                    <div className="detailWrapper">
                        <div className="upperDetails">
                            <div className="logoWrapper">
                                <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/Group%2022_14RP4opB8.png?updatedAt=1714890846865" alt="" />
                            </div>
                            <div className="p_name">
                                <h1 className="heading40px">Road Vision</h1>
                                <p className='universal-paraghraph'>Intelligent system to survey roads in fast and efficient way.
                                </p>
                            </div>
                        </div>
                        <div className={styles.descriptionWrapper} data-aos="fade-left">
                            <p className='universal-paraghraph'>
                                Cheaper, faster, continuous and accurate way of monitoring and reporting road condition
                            </p>
                            <button className='universal-button' style={{ backgroundColor: "#3056D3" }} onClick={() => { window.open("https://vysion.dnnigznr68bge.amplifyapp.com/login/", "_blank") }}>Visit Web Application</button>
                        </div>
                    </div>
                    <div className={styles.animationWrapper} data-aos="fade-right">
                        <div className={styles.center}>
                            <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/Group%2027254_OzdgMSvRo.png?updatedAt=1714890198832" alt="yuvaanFeatures" className={styles.yuvaanFeatures} />
                            <img src="https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/ijiojiojio_IC2Yi2bJt.png?updatedAt=1714890568500" alt="yuvaandevice" className={styles.yuvaanDevice} style={{ transform: "scale(0.8) translateX(10%)" }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
